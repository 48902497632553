.knowledgebase {
  .validation-error {
    color: variables.$color-status-error;
    margin-bottom: 20px;
  }
  .safe-html-paragraph a {
    color: variables.$color-primary;
    &:hover {
      text-decoration: underline;
    }
  }
  display: flex;
  height: 100%;
  flex-direction: column;
  .preline {
    white-space: pre-line;
  }
  .ag-grid-container {
    height: 100%;
  }
  .kb-body {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    &.fixed-wrapper {
      width: 100%;
      max-width: variables.$page-width;
      margin: auto;
      box-sizing: border-box;
      padding: variables.$body-padding;
    }
    .kb-content {
      flex-grow: 1;
      background-color: variables.$lightest;
    }
    h3 {
      margin-top: 24px;
      margin-bottom: 8px;
    }
    .inline-checkboxes {
      margin-top: variables.$element-spacing;
      gap: variables.$element-spacing;
      display: flex;
      justify-content: flex-start;
    }
    .ngx-editor-textfield {
      width: 100%;
      margin-top: 15px;
      .ql-container {
        min-height: 150px;
        height: 100%;
        flex: 1;
        display: flex;
        flex-direction: column;
      }
      .ql-editor {
        height: 100%;
        flex: 1;
        overflow-y: auto;
        width: 100%;
      }
      &.mat-form-field-invalid {
        .textfield-label,
        .editor-statusbar {
          color: variables.$color-status-error;
        }
        .editor-toolbar,
        .CodeMirror {
          border-color: variables.$color-status-error;
        }
      }
    }
    .textfield-label {
      font-size: variables.$font-ms;
    }
    .selectfield-label {
      font-size: variables.$font-sm;
    }
  }
  .kb-list-header {
    justify-content: flex-end;
    padding: 5px;
    margin: 10px;
    display: flex;
    align-items: center;
  }
  .bottom-toolbar {
    margin-top: 32px;
    display: flex;
    justify-content: space-between;
  }
  .spacer {
    padding-right: 10px;
  }
  .right-sidebar {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    flex-grow: 3;
    padding-left: 30px;
  }
}
