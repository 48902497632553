@use 'styles/variables';

.case-details-popup {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  background-color: variables.$lighter-gray;
  .mat-dialog-title {
    margin-bottom: 0px;
  }
  .row-details-container {
    box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.01);
    border: 1px solid variables.$color-lightest-gray;
    background-color: white;
    border-radius: 4px;
    margin: 10px;
  }
  .scrollable-content {
    .mid-container {
      display: flex;
      flex-wrap: wrap;
      gap: 5px;
      background-color: variables.$lighter-gray;
      .mid-child-container {
        flex: 1;
      }
    }
  }
  .centered-heading {
    padding: 10px;
    margin: 5px 0;
  }
  .attributes-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(15rem, auto));
    row-gap: 0.5rem;
    column-gap: 1rem;
    padding: 10px;
    .attribute-content {
      word-break: break-all;
    }
    .notes {
      grid-column: span 2;
      grid-row: span 2;
    }
  }
  .status-tracking-container {
    margin: 0px 30px;
    overflow: unset;
    padding: 0px;
    .flex-container {
      display: flex;
      justify-content: flex-end;
      padding: 16px;
    }
  }
  .echo-result-text {
    padding: 10px 5px;
  }
}
.case-container {
  padding: 0 30px;
  .case-filter-count {
    padding-top: 10px;
    .case-filter-header {
      padding: 0px 18px;
      margin-bottom: 5px;
    }
    .case-filter-sub-header {
      padding: 0px 18px;
    }
  }
  .form-list-controls {
    display: flex;
    gap: 10px;
    .date-control {
      width: 180px;
    }
  }
  .case-filter-controls {
    display: flex;
    gap: 10px;
    justify-content: space-between;
  }
  .grid-toolbar-right {
    display: flex;
    justify-content: space-between;
    gap: 16px;
    padding: 5px 0px;
    .icon-tab-label {
      cursor: pointer;
      font-size: 12px;
    }
  }
  .date-range-error {
    margin-left: 215px;
  }
}
