@use 'sass:color';

:root {
  --ba-color-primary: #0d9fa5;
  --ba-color-secondary: #ffc500;
  --ba-color-warn: #fa6c42;
}
$yellow: #ffc400;
$lighter-yellow: color.adjust($yellow, $lightness: 45%);
$lightest-gray: #faf9f9;
$light-red: #fa6c42;
$green: #1a9f7e;
$light-gray: lightgray;
$gray: gray;
$lightest: #ffffff;
$font: 400 14px / 23px DM Sans;
$mat-button-shadow:
  0px 3px 1px -2px rgb(0 0 0 / 20%),
  0px 2px 2px 0px rgb(0 0 0 / 14%),
  0px 1px 5px 0px rgb(0 0 0 / 12%);
$box-shadow-md:
  0 2px 4px 0 rgb(0 0 0 / 10%),
  0 1px 2px -1px rgb(0 0 0 / 3%);
@mixin box-shadow() {
  box-shadow: 0 0 0 0.2rem rgb(0 0 0 / 1%);
  border: 1px solid #edeae9;
}

@mixin box-shadow-md() {
  box-shadow: 0px 0px 5px rgb(0 0 0 / 10%);
}

@mixin box-shadow-lg() {
  box-shadow: 0 2px 10px -2px hsl(0deg 0% 73% / 66%);
}

@mixin mat-button-shadow() {
  box-shadow: $mat-button-shadow;
}

@mixin page-body() {
  margin: auto;
  max-width: $page-width;
}

@mixin header-title-font() {
  font: 500 17px / 25px DM Sans;
}

$border-radius: 4px;
// Core color palette
$color-primary: #0d9fa5;
$color-secondary: #ffc500;
$color-warn: #fa6c42;
$color-warn-light: #fdd4cd;
$color-white: #faf9f9;
$color-lightest-gray: #edeae9;
$color-light-gray: #7a7575;
$color-gray: #575655;
$color-dark-gray: #1c1c1c;
// Status colors
$color-status-green: #1db891;
$color-status-light-green: #8ebe49;
$color-status-yellow: #ffc401;
$color-status-dark-yellow: #fd9821;
$color-status-red: #fa6c41;
$color-status-error: #fa6d42;

// Performance colors
$color-green: #1eb891;
$color-light-green: #84dbc5;
$color-yellow: #ffc500;
$color-red: #fa6c42;
// Categorical colors
$color-category-1: #ffc500;
$color-category-2: #76a6d0;
$color-category-3: #b3d600;
$color-category-4: #ca688e;
$color-category-5: #32c97b;
$color-category-6: #7e6ab4;
$color-category-7: #e87c5c;
$color-category-8: #426b9a;

// chart dots
$color-dot-gray: rgba(122, 117, 117, 0.2);
$color-dot-gray-border: rgba(122, 117, 117, 0.2);
$color-dot-green: rgba(26, 159, 126, 0.7);
$color-dot-green-border: rgba(7, 44, 35, 0.2);
$color-dot-red: rgba(250, 108, 66, 0.8);
$color-dot-red-border: rgba(224, 56, 6, 0.5);
$color-dot-selected: rgba(14, 64, 165, 0.1);
$color-dot-selected-border: rgba(14, 64, 165, 0.7);

// Performance colors
$gray: #7a7575;
$dark-gray: #575655;
$black: #1c1c1c;
$blue: #0d9fa5;
$lighter-gray: #faf9f9;
$light-yellow: #ffc5001a;
$transparent-yellow: rgba(255, 197, 0, 0.1);
$border-gray: #dddbdb;

// Performance fonts
$font-family: 'DM Sans', sans-serif;
$font-xs: 12px;
$font-sm: 14px;
$font-ms: 16px;
$font-md: 18px;
$font-lg: 24px;
$font-xl: 32px;
// Performance dimensions
$page-width: 1366px;
$spacer: 60px;

// KB Dimensions
$element-spacing: 20px;
$toolbar-height: 110px;
$button-height: 70px;
$bottom-padding: 50px;

//progress tracker chart legend colors
$progress-baseline-legend: rgba(233, 233, 233, 0.6);
$progress-measurement-legend: rgba(186, 226, 216, 0.6);
$progress-incomplete-data-legend: rgba(232, 124, 91, 1);
// App Dimensions
$body-padding: 20px;
$buttons-gap: 8px;
$color-primary-light: color.adjust($color-primary, $lightness: 60%);
$carousel-width: 1000px;
