@use '@angular/material' as mat;
@include mat.core();

$ba-primary-palette: (
  50: #e1f8f8,
  100: #b4eeee,
  200: #84e4e5,
  300: #53d8dc,
  400: #2fcfd5,
  500: #09c6d1,
  600: #0ab6be,
  700: #0da0a5,
  800: #0d8c8e,
  900: #0c6864,
  A100: #a7f9ff,
  A200: #74f6ff,
  A400: #41f2ff,
  A700: #27f1ff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$ba-accent-palette: (
  50: #fff8e1,
  100: #ffedb3,
  200: #ffe182,
  300: #ffd74f,
  400: #ffcc26,
  500: #ffc400,
  600: #ffb500,
  700: #ffa200,
  800: #ff9100,
  900: #ff7100,
  A100: #ffffff,
  A200: #fffaf2,
  A400: #ffe5bf,
  A700: #ffdba6,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #000000,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);
$ba-warn-palette: (
  50: #fae9e7,
  100: #fdcbbb,
  200: #fca990,
  300: #fb8764,
  400: #fa6d42,
  500: #f95321,
  600: #ee4e1d,
  700: #e14719,
  800: #d34015,
  900: #ba330c,
  A100: #ffffff,
  A200: #fff9f8,
  A400: #ffccc5,
  A700: #ffb6ac,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);
$chiron-primary: mat.define-palette($ba-primary-palette, 700);
$chiron-accent: mat.define-palette($ba-accent-palette, 500);
$chiron-warn: mat.define-palette($ba-warn-palette, 500);
$chiron-typography-config: mat.define-typography-config(
  $font-family: 'DM Sans',
  $headline-5: mat.define-typography-level(32px, 42px, 500),
  $headline-6: mat.define-typography-level(24px, 31px, 500),
  $subtitle-1: mat.define-typography-level(17px, 25px, 500),
  $subtitle-2: mat.define-typography-level(16px, 21px, 400),
  $body-2: mat.define-typography-level(14px, 23px, 400),
  $body-1: mat.define-typography-level(14px, 23px, 400),
  $caption: mat.define-typography-level(12px, 16px, 400),
  $button: mat.define-typography-level(14px, 14px, 500, $letter-spacing: normal),
);
$chiron-theme: mat.define-light-theme(
  (
    color: (
      primary: $chiron-primary,
      accent: $chiron-accent,
      warn: $chiron-warn,
    ),
    typography: $chiron-typography-config,
  )
);
@include mat.core-theme($chiron-theme);
@include mat.card-theme($chiron-theme);
@include mat.progress-bar-theme($chiron-theme);
@include mat.tooltip-theme($chiron-theme);
@include mat.form-field-theme($chiron-theme);
@include mat.input-theme($chiron-theme);
@include mat.select-theme($chiron-theme);
@include mat.autocomplete-theme($chiron-theme);
@include mat.dialog-theme($chiron-theme);
@include mat.chips-theme($chiron-theme);
@include mat.slide-toggle-theme($chiron-theme);
@include mat.radio-theme($chiron-theme);
@include mat.slider-theme($chiron-theme);
@include mat.menu-theme($chiron-theme);
@include mat.list-theme($chiron-theme);
@include mat.paginator-theme($chiron-theme);
@include mat.tabs-theme($chiron-theme);
@include mat.checkbox-theme($chiron-theme);
@include mat.button-theme($chiron-theme);
@include mat.icon-button-theme($chiron-theme);
@include mat.fab-theme($chiron-theme);
@include mat.snack-bar-theme($chiron-theme);
@include mat.table-theme($chiron-theme);
@include mat.progress-spinner-theme($chiron-theme);
@include mat.badge-theme($chiron-theme);
@include mat.bottom-sheet-theme($chiron-theme);
@include mat.button-toggle-theme($chiron-theme);
@include mat.datepicker-theme($chiron-theme);
@include mat.divider-theme($chiron-theme);
@include mat.expansion-theme($chiron-theme);
@include mat.grid-list-theme($chiron-theme);
@include mat.icon-theme($chiron-theme);
@include mat.sidenav-theme($chiron-theme);
@include mat.stepper-theme($chiron-theme);
@include mat.sort-theme($chiron-theme);
@include mat.toolbar-theme($chiron-theme);
@include mat.tree-theme($chiron-theme);

@include mat.form-field-density(-4);
@include mat.button-toggle-density(-3);
@include mat.datepicker-density(-2);
@include mat.typography-hierarchy($chiron-typography-config);
mat-form-field {
  @include mat.icon-button-density(-4);
  .mdc-icon-button .material-icons {
    font-size: 20px;
  }
  .mat-mdc-form-field-icon-suffix > .mat-icon {
    padding: 5px;
  }
}
@mixin overrides($theme) {
  $primary-color-palette: map-get($theme, primary);
  .mini-icon-button {
    @include mat.icon-button-density(-5);
    .mdc-icon-button .material-icons {
      font-size: 20px;
    }
  }
  .ba-mat-chip {
    .mat-mdc-standard-chip {
      border-radius: 6px 6px 6px 6px;
      height: 24px;
    }
  }
  .mat-button-toggle.mat-button-toggle-appearance-standard.mat-button-toggle-checked {
    background-color: mat.get-color-from-palette($primary-color-palette);
    color: mat.get-color-from-palette($primary-color-palette, default-contrast);
  }
  a {
    color: inherit;
    text-decoration: none;

    &:hover {
      color: mat.get-color-from-palette($primary-color-palette);
      cursor: pointer;
    }
  }
  //Remove gray background for "fill" appearance form fields.
  .mdc-text-field--filled:not(.mdc-text-field--disabled) {
    background: none;
  }
}
@include overrides($chiron-theme);
html,
body {
  height: 100%;
}

body {
  margin: 0;
  font: 500 14px / 16px DM Sans;
  box-sizing: border-box;
}
