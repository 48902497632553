@use 'variables';

pm-initiatives-table {
  .ag-floating-bottom {
    font-weight: bold;
  }
  .ag-theme-material .ag-ltr {
    .ag-group-expanded,
    .ag-group-contracted {
      margin-right: 12px;
    }
  }
  .ag-theme-material.initiatives-grid .ag-row-footer.ag-row-level-1,
  .ag-row-footer.ag-row-level-2 {
    font-weight: normal;
  }
  .mat-filled-icon {
    font-variation-settings:
      'FILL' 1,
      'wght' 400,
      'GRAD' 0,
      'opsz' 24;
  }
  @for $i from 1 to 4 {
    .ag-theme-material .ag-ltr .ag-row-group-indent-#{$i} {
      padding-left: $i * 15px;
    }
    .ag-theme-material .ag-ltr .ag-row-level-#{$i} .ag-row-group-leaf-indent {
      padding-left: 15px * ($i - 1);
    }
  }
}
.initiatives-performance-grid {
  .ag-theme-material {
    .ag-row {
      border-bottom-style: none;
      height: 30px;
    }
    .ag-cell {
      line-height: 30px;
    }
  }
}
.initiatives-grid {
  .ag-row-footer {
    font-weight: bold;
  }
  .ag-row-footer.ag-row-level--1 {
    background-color: variables.$lighter-gray;
    &.ag-row-hover {
      background-color: variables.$color-lightest-gray;
    }
  }
}
.performance {
  .icon-money-bag {
    background-image: url('/assets/images/performance/money-bag.svg');
    width: 20px;
    height: 24px;
  }
  .icon-money-bag-lg {
    background-image: url('/assets/images/performance/group@3x.svg');
    width: 28px;
    height: 35px;
  }
  .list-container {
    box-sizing: border-box;
    border: 1px solid variables.$border-gray;
    border-radius: variables.$border-radius;
    background-color: #ffffff;
    @include variables.box-shadow();
    margin-top: 10px;
    & > .title {
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 15px 30px;
      h1 {
        margin: 0;
        font-size: variables.$font-md;
        flex-grow: 1;
        flex-shrink: 0;
      }
      & > div {
        flex-grow: 0;
        flex-shrink: 1;
        display: flex;
        gap: 6px;
        background-color: variables.$transparent-yellow;
        border: solid 1px variables.$transparent-yellow;
        border-radius: variables.$border-radius;
        padding: 10px 20px;
      }
      .select {
        flex-basis: 10em;
        padding: 0.5em;
      }
    }
    .display-name {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .performance-text {
      text-align: right;
    }
    & > .content {
      display: flex;
      align-items: center;
      padding: 15px 30px;
      border-top: solid 1px variables.$border-gray;
      gap: 1em;
      .title {
        flex-basis: 50%;
        flex-grow: 0;
        flex-shrink: 0;
        width: 50%;
        :nth-child(1) {
          font-size: variables.$font-ms;
          color: variables.$black;
        }
        :nth-child(2) {
          font-size: variables.$font-xs;
          color: variables.$gray;
        }
        .time-period {
          font-size: variables.$font-xs;
          color: variables.$gray;
        }
      }
      .chart {
        flex-grow: 1;
        flex-shrink: 0;
      }
      .days {
        flex-grow: 0;
        flex-shrink: 1;
        font-size: variables.$font-sm;
        color: variables.$black;
        span {
          font-size: variables.$font-xs;
          color: variables.$gray;
        }
      }
      .menu {
        visibility: hidden;
        flex-grow: 0;
        flex-shrink: 1;
      }
    }
    & > :nth-child(2) {
      .list-container {
        .title {
          padding-top: 18px;
        }
      }
    }
  }
  .selected {
    background-color: variables.$lighter-gray;
  }
  //@Rajee progress tracker chart highcharts formatting - if I put it in another component that
  //contains the progress chart, the styling doesn't work. TODO: should
  //figure this out and put it in the container that contains the chart.
  // https: //biome-analytics.atlassian.net/browse/PROD-10111
  .progress-legend-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
    font-family: 'DM Sans', sans-serif;
  }
  .progress-baseline-legend {
    background-color: variables.$progress-baseline-legend;
    width: 15px;
    height: 15px;
  }
  .progress-measurement-legend {
    background-color: variables.$progress-measurement-legend;
    width: 15px;
    height: 15px;
  }
  .progress-incomplete-data-legend {
    background-color: variables.$progress-incomplete-data-legend;
    width: 10px;
    height: 10px;
    border-radius: 50%;
  }
  .progress-overlay-legend {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
  }
  .progress-overlay-legend-text {
    max-width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
