@use '@angular/material' as mat;
@use 'variables';
@use 'material.theme';
@use 'sass:math';
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Anton:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap');
@import '@ag-grid-community/styles/ag-grid.css';
@import '@ag-grid-community/styles/ag-theme-alpine.css';
@import '@ag-grid-community/styles/ag-theme-material.css';
@import './icons.scss';
@import './initiatives.scss';
@import './knowledgebase.scss';
@import './dashboards.scss';
@import './admin.scss';
@import './cases.scss';

html,
body {
  width: 100vw;
  height: 100vh;
  margin: 0;
  overflow: hidden;
}
html * {
  box-sizing: border-box;
}
.ag-theme-material {
  --ag-font-family: 'DM Sans', sans-serif;
}
.color-primary {
  color: variables.$color-primary;
}
.color-warn {
  color: var(--ba-color-warn);
}
.no-margin {
  margin: 0 !important;
}
.flex-container {
  display: flex;
}
.dashboard-finder .mat-mdc-tab-body-wrapper {
  height: 100%;
}
.flex-column-container {
  display: flex;
  flex-direction: column;
}
.flex-auto-spacer {
  flex: 1 1 auto;
}
.flex-half {
  flex-basis: 50%;
}
.hidden {
  display: none;
}
.center-text {
  text-align: center;
}
.full-width {
  width: 100%;
}
.cursor-pointer {
  cursor: pointer;
}
.white-space-nowrap {
  white-space: nowrap;
}
.buttons-container,
.buttons-container-right {
  display: flex;
  align-items: center;
  height: 37px;
  gap: variables.$buttons-gap;
}
.buttons-container-right {
  justify-content: flex-end;
}
.back-button {
  font-size: 12px;
  .inline-icon {
    font-size: 18px;
    vertical-align: text-top;
  }
}
.app-main-container {
  height: 100vh;
  width: 100vw;
  display: flex;
  overflow: hidden;
  flex-direction: column;
}
.app-content-container {
  flex: 1 1 auto;
  overflow: auto;
}
.app-drawer-container {
  height: 100%;
  width: 100%;
  overflow: visible;
  background: none;
  .app-side-nav {
    width: 275px;
    max-width: 50%;
    background-color: variables.$lightest;
    overflow: visible;
    ::-webkit-scrollbar {
      width: 8px;
      background: 0;
    }
    ::-webkit-scrollbar-thumb {
      background: rgba(0, 0, 0, 0.26);
      border-radius: 8px;
    }
    &:hover {
      --fix: ; /* This custom property invalidates styles on hover, thereby enforcing a style recomputation. This is needed to work around a bug in Safari. */
    }
  }
}
.ba-card {
  border-radius: 4px;
  background-color: white;
  padding: 12px;
  @include variables.box-shadow();
}
.toolbar {
  .mat-mdc-tab-link {
    min-width: 80px;
    flex-direction: column;
  }
}
.opportunities-section {
  padding: 25px 60px;
}

.overview-chart {
  width: 100%;
  height: 350px;
  display: block;
}

/* Three dots loading animation */
$dot-size: 4px;
$dot-border-radius: math.div($dot-size, 2);
$dot-color: black;
$dot-background-color: rgb(192, 192, 192);

.dot-sm-a,
.dot-sm {
  width: 11px;
  height: 11px;
  border-radius: 50%;
  border: 1px solid transparent;
  margin: 5px;
}
.dot-sm-a {
  margin: 2px 5px;
}
.light-red {
  color: variables.$light-red;
}
.green {
  color: variables.$green;
}
.legend-dot {
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 4px;
}
.legend-dot,
.dot-sm,
.dot-sm-a {
  &.light-red {
    background-color: variables.$color-dot-red;
    border-color: variables.$color-dot-red-border;
  }

  &.green {
    background-color: variables.$color-dot-green;
    border-color: variables.$color-dot-green-border;
  }

  &.light-gray {
    background-color: variables.$color-dot-gray;
    border-color: variables.$color-dot-gray-border;
  }
}

.dot-flashing {
  margin: 10% 0 0 25%;
  position: relative;
  width: $dot-size;
  height: $dot-size;
  border-radius: $dot-border-radius;
  background-color: $dot-background-color;
  color: $dot-color;
  animation: dotFlashing 1s infinite linear alternate;
  animation-delay: 0.5s;
}

.dot-flashing::before,
.dot-flashing::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
}

.dot-flashing::before {
  left: -($dot-size * 1.5);
  width: $dot-size;
  height: $dot-size;
  border-radius: $dot-border-radius;
  background-color: $dot-background-color;
  color: $dot-color;
  animation: dotFlashing 1s infinite alternate;
  animation-delay: 0s;
}
.dot-flashing::after {
  left: ($dot-size * 1.5);
  width: $dot-size;
  height: $dot-size;
  border-radius: $dot-border-radius;
  background-color: $dot-background-color;
  color: $dot-color;
  animation: dotFlashing 1s infinite alternate;
  animation-delay: 1s;
}

@keyframes dotFlashing {
  0% {
    background-color: $dot-color;
  }
  50%,
  100% {
    background-color: $dot-background-color;
  }
}

.charts-section {
  background-color: variables.$lightest-gray;

  .charts-container {
    display: flex;
    min-height: 400px;
    column-gap: 20px;
  }
}
.chart-container {
  @include variables.box-shadow();
  column-gap: 10px;
  width: 33%;
  background-color: white;
  border-radius: 4px;
}

.chart-header {
  padding: 20px 15px 5px;

  .info-icon {
    font-size: 18px;
    position: relative;
    bottom: -3px;
  }
}

.sparkline-container {
  padding: 0 5px;
  width: 50px;
  height: 20px;
  display: block;
}

.arrow-container {
  width: 15px;
  margin-left: auto;
  margin-right: auto;
  &.up {
    margin-bottom: -10px;
  }
  &.down {
    margin-top: -10px;
    margin-bottom: -4px;
  }
  .arrow {
    border: solid lightgray;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    width: 0.5em;
    height: 0.5em;
    z-index: 1;
    &:hover {
      cursor: pointer;
      border-color: darkgray;
    }
  }
  .arrow.up {
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
  }
  .arrow.down {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    margin-top: -10px;
  }
}
/* popup dialog with auto height*/
.auto-height-dialog {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 100%;
  .dialog-header {
    flex: 0 0 30px;
    height: 30px;
    padding-bottom: 16px;
  }
  .dialog-footer {
    display: flex;
    justify-content: right;
    align-items: center;
    flex: 0 0 50px;
    height: 50px;
    padding: 20px 0 0;
  }
  .dialog-auto-height-content {
    flex: 1 1 auto;
  }
}
/* For horizontal label tabs e.g. Dashboards */
.icon-tab-label {
  display: flex;
  gap: 5px;
  align-items: center;
}
/* Add this class as well for vertical labels such as top nav */
.icon-tab-label.vertical {
  flex-direction: column;
  gap: 0;
  &:hover {
    color: variables.$color-primary;
  }
}
.ba-header,
.charts-section {
  .mdc-tab__ripple {
    display: none;
  }
}
/* sidenav menu items */
.mat-mdc-list-base {
  &.submenu {
    padding: 0;
    .mat-mdc-list-item.menu-item {
      padding-left: 30px;
    }
  }
  .mat-mdc-list-item.menu-item {
    font: variables.$font;
    font-weight: 500;
    height: 48px;
    &:hover {
      .icon,
      .label {
        color: variables.$color-primary;
      }
    }
    &.active {
      border-right: 2px solid variables.$color-primary;
      background-color: variables.$lightest-gray;
    }
    .icon {
      margin-right: 10px;
      color: variables.$color-gray;
    }
    .label {
      color: variables.$color-gray;
    }
  }
}
/* material overrides*/
.mat-mdc-tab-header.mat-mdc-tab-nav-bar-stretch-tabs .mat-mdc-tab-link {
  flex-grow: 0;
}
.mat-mdc-tab {
  flex-grow: 0 !important;
}
.tabs-container {
  height: 100vh;
}
.mat-mdc-tab-group {
  height: 100%;
}
.full-height {
  height: 100%;
}
.bg-status-green {
  background-color: variables.$color-status-green;
}
.bg-status-light-green {
  background-color: variables.$color-status-light-green;
}
.bg-status-yellow {
  background-color: variables.$color-status-yellow;
}
.bg-status-dark-yellow {
  background-color: variables.$color-status-dark-yellow;
}
.bg-status-red {
  background-color: variables.$color-status-red;
}
.color-status-green {
  color: variables.$color-status-green;
}
.color-status-light-green {
  color: variables.$color-status-light-green;
}
.color-status-yellow {
  color: variables.$color-status-yellow;
}
.color-status-dark-yellow {
  color: variables.$color-status-dark-yellow;
}
.color-status-red {
  color: variables.$color-status-red;
}
.color-status-gray {
  color: variables.$color-light-gray;
}

oe-measures-grid .ag-row.ag-row-pinned {
  background-color: variables.$lightest-gray;
}

.grid-cell-centered {
  justify-content: center;
}
.icon-suggestion-main {
  background-image: url('/assets/images/performance/suggestion-main.svg');
  width: 48px;
  height: 46px;
}
.icon {
  align-self: center;
  background-repeat: no-repeat;
  background-position-y: center;
  white-space: nowrap;
  direction: ltr;
  word-wrap: normal;
}
.light-text {
  color: variables.$color-light-gray;
}

oe-measures-grid .autogroup-cell .ag-header-cell-label {
  padding-left: 42px;
}
.error-page-container {
  min-width: 200px;
  max-width: 500px;
  margin: 100px auto;
  text-align: center;
}
.ba-chip {
  width: 100%;
  .mat-mdc-standard-chip {
    max-width: 10em;
  }
  .mat-chip-remove-icon {
    color: variables.$color-primary;
    background-color: variables.$color-white;
    border-radius: 10px;
  }
}
/* @Areeba TODO - In Material 15, the overflow property of the chips is set to visible, due to which text wrap/ellipses
   does not work. This is an open issue on github, so we will remove these inner classes once the issue is fixed in next version update */
.mdc-evolution-chip-set__chips {
  max-width: 100% !important;
}
.mat-mdc-standard-chip .mdc-evolution-chip__cell--primary,
.mat-mdc-standard-chip .mdc-evolution-chip__action--primary,
.mat-mdc-standard-chip .mat-mdc-chip-action-label {
  overflow: hidden !important;
}
.ag-theme-material .ag-layout-auto-height .ag-center-cols-clipper,
.ag-theme-material .ag-layout-auto-height .ag-center-cols-container,
.ag-theme-material .ag-layout-print .ag-center-cols-clipper,
.ag-theme-material .ag-layout-print .ag-center-cols-container {
  min-height: 50px;
}
.ag-theme-material
  .ag-ltr
  .ag-cell-focus:not(.ag-cell-range-selected):focus-within,
.ag-theme-material .ag-ltr .ag-cell-range-single-cell {
  border: none;
}
.ag-theme-material.policies-grid
  .ag-overlay-no-rows-wrapper.ag-layout-auto-height {
  padding-top: 90px;
}
.ag-theme-material .ag-rich-select .ag-rich-select-list {
  width: auto !important;
}
.cases-grid {
  .ag-theme-material .ag-rich-select .ag-rich-select-list {
    min-width: 300px;
    .ag-rich-select-row {
      white-space: break-spaces;
    }
  }
  .ag-rich-select {
    width: 400px !important;
  }
}
.file-tracker .ag-theme-material {
  --ag-grid-size: 6px;
  --ag-icon-size: 16px;
  --ag-font-size: 12px;
  --ag-row-height: calc(var(--ag-grid-size) * 8);
  --ag-header-height: calc(var(--ag-grid-size) * 8);
  --ag-list-item-height: calc(var(--ag-grid-size) * 6);
  --ag-row-group-indent-size: calc(
    var(--ag-grid-size) * 2 + var(--ag-icon-size)
  );
  --ag-cell-horizontal-padding: calc(var(--ag-grid-size) * 2);
  --ag-input-height: calc(var(--ag-grid-size) * 3);
}
.client-data-snapshot .ag-theme-material {
  --ag-row-group-indent-size: 20px;
}
.risk-audit-grid {
  .ag-theme-material .ag-rich-select .ag-rich-select-list {
    min-width: 250px;
    height: auto;
    padding-bottom: 15px;
  }
}

.access-message-box {
  margin: 60px 30px;
  padding: 10px 20px;
  background: variables.$color-white;
  border-radius: variables.$border-radius;
  box-shadow: 0 0 0 0.2rem rgb(0 0 0 / 1%);
  p {
    margin: 0;
  }
}
.ag-theme-material {
  --ag-material-primary-color: var(--ba-color-primary);
  --ag-material-accent-color: var(--ba-color-primary);
  .in-progress-cell {
    background-color: variables.$lightest-gray;
  }
  .warning-cell {
    background-color: variables.$light-yellow;
  }
  .success-cell {
    background-color: variables.$color-light-green;
  }
  .error-cell {
    background-color: variables.$light-red;
  }
}
.no-record-found {
  padding: 25px;
  text-align: center;
  margin: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.tenant-menu-list.mat-mdc-menu-panel.mat-mdc-menu-panel,
.mail-box-menu.mat-mdc-menu-panel.mat-mdc-menu-panel,
.user-menu.mat-mdc-menu-panel.mat-mdc-menu-panel {
  max-width: 100%;
}
.search-icon {
  color: variables.$gray;
}
.file-icon {
  min-width: 32px;
  min-height: 32px;
  color: variables.$gray;
  font-size: 32px;
  font-variation-settings:
    'wght' 350,
    'opsz' 32;
}
/* Confluence internal classes */
.confluence-embedded-image {
  max-width: -webkit-fill-available;
  box-shadow: variables.$box-shadow-md;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.external-link {
  color: variables.$color-primary;
  &:hover {
    text-decoration: underline;
  }
}
.confluenceTable {
  width: 100%;
  border-collapse: collapse;
  box-shadow: variables.$box-shadow-md;
  th,
  td {
    border: 1px solid variables.$gray;
    padding: 0 10px;
    word-wrap: break-word;
  }
  th {
    background-color: variables.$light-gray;
  }
  td {
    img {
      max-width: 300px;
    }
  }
}
.embedded-youtube-video {
  display: flex;
  justify-content: center;
  padding: 10px 0;
  iframe {
    width: 70%;
    height: 350px;
    border-radius: 4px;
  }
}
.confluence-page-content {
  .copy-icon {
    font-size: max(1em, 20px);
    visibility: hidden;
    cursor: pointer;
    position: relative;
    top: 6px;
    left: 10px;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    &:hover .copy-icon {
      visibility: visible;
    }
  }
}
.filter-bar .opportunities-search-trigger {
  min-width: 250px;
  .mat-badge-content {
    font-size: x-small;
  }
}
.expandable-menu {
  .mat-expansion-panel-body {
    padding: 0 0 0 24px;
  }
  .mdc-list-item:hover::before {
    background-color: unset;
  }
}
.ag-theme-material-small {
  --ag-grid-size: 5px;
  --ag-icon-size: 16px;
  --ag-font-size: 12px;
  --ag-row-height: calc(var(--ag-grid-size) * 7);
  --ag-header-height: calc(var(--ag-grid-size) * 8);
  --ag-list-item-height: calc(var(--ag-grid-size) * 6);
  --ag-row-group-indent-size: calc(
    var(--ag-grid-size) * 3 + var(--ag-icon-size)
  );
  --ag-cell-horizontal-padding: calc(var(--ag-grid-size) * 3);
  --ag-input-height: calc(var(--ag-grid-size) * 4);
  .ag-body {
    .ag-row {
      height: var(--ag-row-height) !important ;
    }
  }
}
.ag-theme-material-medium {
  --ag-grid-size: 6px;
  --ag-icon-size: 17px;
  --ag-font-size: 12px;
  --ag-row-height: calc(var(--ag-grid-size) * 7);
  --ag-header-height: calc(var(--ag-grid-size) * 8);
  --ag-list-item-height: calc(var(--ag-grid-size) * 6);
  --ag-row-group-indent-size: calc(
    var(--ag-grid-size) * 3 + var(--ag-icon-size)
  );
  --ag-cell-horizontal-padding: calc(var(--ag-grid-size) * 3);
  --ag-input-height: calc(var(--ag-grid-size) * 4);
  .ag-body {
    .ag-row {
      height: var(--ag-row-height) !important ;
    }
  }
}
.ag-theme-material .ag-tab {
  --material-grid-size: 8px;
  height: calc(var(--material-grid-size) * 4.5);
  flex: 1 1 auto;
}
.ag-theme-material .ag-tabs-header,
.ag-theme-material .ag-column-drop-horizontal {
  background-color: var(--ag-subheader-background-color);
}
.ag-theme-material .ag-tabs-body {
  padding: calc(var(--material-grid-size) * 0.5) 0;
}
.ag-theme-material .ag-tabs-body .ag-menu-list {
  padding-top: 0;
  padding-bottom: 0;
}
.ag-theme-material .ag-side-button {
  height: 80px;
  font-size: 11px;
}
.ag-button.ag-side-button-button {
  min-height: calc(var(--ag-grid-size) * 10);
}
.tooltip-unlimited-height .mdc-tooltip__surface {
  max-height: unset;
}
.tooltip-large-width .mdc-tooltip__surface {
  max-width: 500px;
  width: auto;
  padding: 10px;
  max-height: unset;
}
.bg-color-lightest {
  background-color: variables.$color-lightest-gray;
}
.bg-color-lighter {
  background-color: mix(black, variables.$color-lightest-gray, 5%);
}
.bg-color-light {
  background-color: mix(black, variables.$color-lightest-gray, 10%);
}
.bg-color-dark {
  background-color: mix(black, variables.$color-lightest-gray, 15%);
}
.bg-color-darker {
  background-color: mix(black, variables.$color-lightest-gray, 20%);
}
.bg-color-darkest {
  background-color: mix(black, variables.$color-lightest-gray, 25%);
}
.dashboard-finder-tabs {
  .mdc-tab {
    min-width: 125px;
    padding-left: 10px;
    padding-right: 10px;
  }
}
.ag-theme-material-striped .ag-row-even {
  background-color: mix(black, variables.$lighter-gray, 2%);
}
.ag-sort-order {
  content-visibility: hidden;
}
.info-panel {
  grid-column: 1/-1;
  padding: 10px;
  background-color: variables.$lighter-yellow;
  .header {
    display: flex;
    align-items: center;
    .title {
      font-size: 18px;
      font-weight: 500;
      flex-grow: 2;
      padding-bottom: 5px;
    }
  }
}
.angioscope-details-checkbox .mdc-form-field {
  width: 100%;
}
.angioscope-details-checkbox .mdc-label {
  width: 100%;
}
.right-panel {
  ul,
  ol {
    margin-top: 5px;
    padding-left: 20px;
    li * {
      margin: 0;
    }
  }
  p {
    margin-bottom: 0;
  }
}
.message-detail {
  a {
    color: variables.$color-primary;
  }
}
.element-description {
  .dependency-link {
    color: variables.$color-primary;
  }
  .dependency-link:hover {
    text-decoration: underline;
  }
}
