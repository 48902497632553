%svg-common {
  background: url('/assets/icons-svg/icons.svg') no-repeat;
  display: inline-grid;
}

.icon-xs {
  width: 19px;
  height: 24px;
}

.icon-sm {
  width: 23px;
  height: 24px;
}

.icon-md {
  width: 30px;
  height: 28px;
}

.icon-lg {
  width: 36.265px;
  height: 34.5px;
}

.icon-xl {
  width: 60px;
  height: 50px;
}

.icon-hospital-bed {
  @extend %svg-common;
  background-position: 65.98255033557047% 92.5925925925926%;
}

.icon-dollar-bag {
  @extend %svg-common;
  background-position: 38.53911077425073% 92.59430730198709%;
}

.icon-file-folder {
  @extend %svg-common;
  background-position: 98.78982464806126% 0;
}

.icon-suggestion {
  @extend %svg-common;
  background-position: 0 100%;
}

.icon-welcome-hi {
  @extend %svg-common;
  background-position: 0 0;
}

/*** @Faheem: Icons for PM. These icons are not part of the sprite yet **/
.md-icon-1x {
  font-size: 12px;
}
.md-icon-2x {
  font-size: 16px;
}
.md-icon-3x {
  font-size: 20px;
}
.icon-1x {
  font-size: 12px;
  width: 12px;
  height: 12px;
}
.icon-2x {
  font-size: 16px;
  width: 16px;
  height: 16px;
}
.icon-3x {
  font-size: 20px;
  width: 20px;
  height: 20px;
}
.icon-4x {
  width: 24px;
  height: 24px;
}
.icon-5x {
  width: 28px;
  height: 28px;
}
.icon-8x {
  width: 40px;
  height: 40px;
}
.icon-base {
  display: inline-block;
  align-self: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position-y: center;
  white-space: nowrap;
  direction: ltr;
  word-wrap: normal;
}
.icon-objective {
  background-image: url('/assets/images/performance/objective-md.svg');
}
.icon-calendar {
  background-image: url('/assets/images/performance/calendar@3x.svg');
}
.icon-calendar-md {
  background-image: url('/assets/images/performance/calendar-3@3x.svg');
}
.icon-money-bag {
  background-image: url('/assets/images/performance/money-bag.svg');
}
.icon-tactic {
  background-image: url('/assets/images/performance/tactic-md.svg');
}
.icon-capture-context {
  background-image: url('/assets/images/performance/capture-context-md.svg');
}
.icon-calendar-date {
  background-image: url('/assets/images/performance/target-date-md.svg');
}
.icon-suggestion {
  background-image: url('/assets/images/performance/suggestion-main.svg');
}
.icon-stakeholders {
  background-image: url('/assets/images/performance/different-stakeholders-md.svg');
}
.icon-stakeholders-same {
  background-image: url('/assets/images/performance/stakeholders-md.svg');
}
.icon-priority-1 {
  background-image: url('/assets/images/performance/priority-1.svg');
}
.icon-priority-2 {
  background-image: url('/assets/images/performance/priority-2.svg');
}
.icon-priority-3 {
  background-image: url('/assets/images/performance/priority-3.svg');
}
.icon-upload {
  background-image: url('/assets/images/performance/upload-bottom.svg');
}
.icon-opportunities {
  background-image: url('/assets/icons-svg/opportunities.svg');
}
.icon-dashboards {
  background-image: url('/assets/icons-svg/dashboards.svg');
}
.icon-knowledge {
  background-image: url('/assets/icons-svg/knowledge.svg');
}
.icon-performance {
  background-image: url('/assets/icons-svg/initiatives.svg');
}
.icon-information {
  background-image: url('/assets/images/performance/information-circle@3x.svg');
}
.icon-analytics-graph {
  background-image: url('/assets/images/performance/analytics-graph@3x.svg');
}
.icon-hospital-bedroom {
  background-image: url('/assets/images/performance/hospital-bedroom.svg');
}
.icon-target-md {
  background-image: url('/assets/images/performance/target-md.svg');
}
.icon-add {
  background-image: url('/assets/images/performance/add.svg');
}
.icon-close {
  background-image: url('/assets/images/performance/ic-close-24px.svg');
}
.icon-signal-md-sm {
  background-image: url('/assets/images/performance/signal-medium@3x.svg');
  width: 16px;
  height: 9px;
}
.icon-suggestion-single {
  background-image: url('/assets/images/performance/suggestion-single.svg');
  width: 10px;
  height: 14px;
}
.icon-calendar-sm {
  background-image: url('/assets/images/performance/calendar@3x.svg');
  width: 18px;
  height: 18px;
}
.icon-refresh-sm {
  background-image: url('/assets/images/performance/button-refresh-arrows@3x.svg');
  width: 16px;
  height: 14px;
}
.icon-hospital-sm {
  background-image: url('/assets/images/performance/hospital-building-1@3x.svg');
  width: 18px;
  height: 18px;
}
.icon-hospital-bed-lg {
  background-image: url('/assets/images/performance/hospital-bedroom@3x.svg');
  width: 34px;
  height: 35px;
}
.icon-hospital-md {
  background-image: url('/assets/images/performance/hospital-md.svg');
}
