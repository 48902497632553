.ag-theme-material {
  .valid-row {
    border-left: 2px solid transparent;
  }
  .draft-row {
    border-left: 2px solid variables.$color-primary;
  }
  .removed-row,
  .error-row {
    border-left: 2px solid variables.$color-warn;
  }
  .error-text {
    color: variables.$color-warn;
  }
  .roles-grid-description {
    word-break: break-word;
    line-height: 30px;
  }
}
.privileges-form {
  display: flex;
  flex-direction: column;
  padding: 10px;
  gap: 5px;
  height: 100%;
}
.dialog-content-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  .grid {
    height: 100%;
  }
}
.admin-details-container {
  .admin-form-heading {
    padding: variables.$body-padding;
    h2 {
      display: flex;
      align-items: center;
      margin: 0;
    }
  }
  .admin-form {
    padding: variables.$body-padding;
    display: flex;
    flex-direction: column;
    gap: 30px;
    .form-fields,
    .account-form-fields {
      flex: 1 1 auto;
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(300px, auto));
      grid-column-gap: 50px;
      grid-row-gap: 20px;
      .checkbox {
        display: flex;
        align-items: center;
      }
      .slider-container {
        display: flex;
        flex-direction: column;
        .slider-header {
          display: flex;
          justify-content: space-between;
        }
      }
      mat-form-field {
        align-self: flex-start;
      }
    }
    .account-form-fields {
      grid-template-columns: repeat(auto-fill, minmax(600px, auto));
    }
    .read-only p {
      color: variables.$color-light-gray;
    }
    .grid-header {
      display: flex;
      justify-content: space-between;
      padding-top: 10px;
      background-color: variables.$lighter-gray;
      border-radius: 4px;
      padding: 8px;
      h3 {
        margin: 0;
        padding: 5px 0;
      }
    }
  }
}
.status-section-header {
  background-color: variables.$color-lightest-gray;
}
.care-entity-section-header {
  background-color: mix(black, variables.$color-lightest-gray, 5%);
}
.dataset-section-header {
  background-color: mix(black, variables.$color-lightest-gray, 10%);
}
.admin-list-container {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  overflow: auto;
}
adm-grid-list-field {
  .ag-header-group-cell-label {
    justify-content: center;
  }
  .ag-theme-material .ag-checkbox-input-wrapper.ag-checked::after {
    color: variables.$color-primary;
  }
}
.admin-tokens-grid {
  .revoke-icon {
    color: variables.$color-warn;
  }
  .allow-icon {
    color: variables.$color-primary;
  }
}
.admin-role-code-prefix .mat-form-field-icon-prefix {
  align-self: baseline;
}
.sample-import-table {
  width: 100%;
  margin-bottom: 20px;
  border-collapse: collapse;
  border: 1px solid variables.$light-gray;
  tr,
  tr:not(:last-child) {
    text-align: center;
    border-bottom: 1px solid variables.$light-gray;
  }
}
