.ae {
  .icon-recently-viewed {
    background-image: url('/assets/images/ae/icons/recently-viewed.svg');
  }
  .icon-your-favorites {
    background-image: url('/assets/images/ae/icons/favorited.svg');
  }
  .icon-most-favorited {
    background-image: url('/assets/images/ae/icons/most-favorited.svg');
  }
  .icon-shared {
    background-image: url('/assets/images/ae/icons/shared.svg');
  }
  .icon-custom-dashboards {
    background-image: url('/assets/images/ae/icons/custom-dashboards.svg');
  }
  .icon-quick-links {
    background-image: url('/assets/images/ae/icons/quick-links.svg');
  }
  .full-height {
    height: 100%;
  }
  .full-width {
    width: 100%;
  }
  .dashboard-finder {
    .ag-theme-material {
      .ag-header,
      .ag-row {
        border-bottom: none;
      }
      .ag-row-hover .favorite-unselected {
        display: block;
      }
      .favorite-cell {
        padding-right: 0px;
      }
      .ag-row-selected::before {
        background: transparent;
        border-right: 2px solid variables.$color-primary;
        margin-right: 1px;
      }
    }
    @for $i from 1 to 4 {
      .ag-row-group-indent-#{$i} {
        padding-left: $i * 15px;
      }
      .ag-row-level-#{$i} .ag-row-group-leaf-indent {
        padding-left: 15px * ($i - 1);
      }
    }
    .ag-group-expanded,
    .ag-group-contracted {
      margin-right: 10px;
    }
    .ag-cell {
      padding-left: 10px;
    }
  }
}
.ae-cards-grid {
  --card-min-width: 235px;
  --cards-gap: 20px;
}
